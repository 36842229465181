import Head from 'next/head';
import gtm from './gtm';
import klaviyo from './klaviyo';
import hotjar from './hotjar';
import chat from './chat';
import viralSwip from './viralSwip';
import facebookPixel from './facebook-pixel';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const icons = () => {
  const config = ['16', '32', '64', '128'];
  const getIcon = (size) => {
    switch (size) {
      case '16':
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon16.png?v=1672187508';
      case '32':
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon32.png?v=1672187508';
      case '64':
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon64.png?v=1672187508';
      case '128':
        return 'https://cdn.shopify.com/s/files/1/0522/3320/7988/files/FavIcon128.png?v=1672187508';
      default:
        return '';
    }
  };
  return config.map((size) => (
    <link rel="icon" key={size} size={`${size}x${size}`} href={getIcon(size)} />
  ));
};

const LayoutHead = () => {
  const router = useRouter();
  let interval;
  function setFrontChatPosition() {
    const iframe = document.getElementById('launcher');
    if (iframe === null) {
      return;
    }
    iframe.style.bottom = '80px';
 
    iframe.style.transition = 'all 0.5s ease-in-out';
    if (iframe !== null) {
      if (iframe.style.bottom.toString() === '0px') {
        iframe.style.scale = '1';
        iframe.style.bottom = '0px';
        return;
      }
      if (
        iframe.style.bottom.toString() === '135px' &&
        router.pathname !== '/pc-models/[uid]' &&
        router.pathname !== '/products/[...handle]'
      ) {
        iframe.style.bottom = '4px';
        return;
      }
      if (
        router.pathname === '/pc-models/[uid]' ||
        router.pathname === '/products/[...handle]'
      ) {
        const windowWidth = window.innerWidth;
        if (windowWidth > 1700) {
          iframe.style.scale = '1';
          iframe.style.bottom = '4px';
        } else if (windowWidth <= 1700 && windowWidth >= 1025) {
          iframe.style.scale = '1';
          iframe.style.bottom = '80px';
        } else {
          iframe.style.scale = '0.8';
          iframe.style.bottom = '125px';
          iframe.style.right = '-10px';
        }

        return;
      }
      iframe.style.scale = '1';
      iframe.style.bottom = '4px';
    }
  }
  function setFrontChatDefaultPosition() {
    const iframe = document.getElementById('launcher');
    if (iframe !== null) {
      if (iframe.style.bottom.toString() === '4px') {
        clearInterval(interval);
      }
      iframe.style.transition = 'all 0.5s ease-in-out';
      iframe.style.scale = '1';
      iframe.style.right = '9px';
      iframe.style.bottom = '4px';
      return;
    }
  }
  useEffect(() => {
    clearInterval(interval);
    if (
      router.pathname !== '/pc-models/[uid]' &&
      router.pathname !== '/products/[...handle]'
    ) {
      clearInterval(interval);
      interval = setInterval(setFrontChatDefaultPosition, 1000);
    } else {
      clearInterval(interval);
      interval = setInterval(setFrontChatPosition, 1000);
    }
    return () => clearInterval(interval);
  }, [router]);

  const url =
    process.env.NEXT_PUBLIC_STOREFRONT_URL || 'https://aftershockpc.com/';
  const title = 'AFTERSHOCK PC - Custom Gaming PCs and Notebooks';
  const description =
    "Malaysia's favorite custom PC brand. With over 250000+ happy customers in 3 different countries, we are relentless in our pursuit of excellence. AFTERSHOCK PC prides itself in delivering the ultimate custom PC experience, with a commitment to deliver service that is a step above the rest. Voted 4 times as the winner of the best gaming pc brand in Singapore, you can rest assured that we will build you the perfect PC to fit your needs.";
  const keywords =
    'aftershock, custom gaming computers, custom pcs, custom notebooks, custom workstations';

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="robots" content="index,follow" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=yes, viewport-fit=cover"
        />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />

        <meta key="og:url" property="og:url" content={url} />
        <meta key="og:title" property="og:title" content={title} />
        <meta
          key="og:description"
          property="og:description"
          content={description}
        />
        <meta name="twitter:site" content={url} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="/images/ogImage.png" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/ogImage.png" />

        {icons()}
        {gtm}
        {hotjar}
        {facebookPixel}
      </Head>
      {chat}
      {klaviyo}
      {viralSwip}
      {/* 
        This break design for the slider in the survey
         <br
          className="Apple-interchange-newline"
          style={{ height: 0, width: 0 }}
        /> */}
    </>
  );
};

export default LayoutHead;
