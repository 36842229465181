/* eslint-disable react/prop-types */
import React, { useState, memo, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import slugify from 'slugify';
import Router from 'next/router';
import breakPoints from 'helpers/Styles';
import TertiaryMobile from '../TertiaryMobile';

const SecondaryMobile = ({
  items,
  secondaryHeader,
  tabs,
  onClose,
  goBack,
  ksps,
  parentLink,
}) => {
  Router.onRouteChangeStart = () => {
    onClose();
  };
  const [tertiaryItems, setTertiaryItems] = useState(items);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [kspsItems, setKspsItems] = useState();
  useEffect(() => {
    if (tabs[0]) {
      setTertiaryItems(
        items.filter(
          (n) => n.primary.column_handle === tabs[0]?.mega_nav_column_handle,
        ),
      );
      setKspsItems(
        ksps.filter(
          (n) => n.primary.column_handle === tabs[0]?.mega_nav_column_handle,
        ),
      );
    }
  }, []);
  return (
    <div>
      <div className="wrapper secondary_menu_header">
        <div aria-hidden="false" onClick={() => goBack()}>
          <img draggable="false"
            src="/images/arrow-left.png"
            alt="first_level menu"
            layout="fixed"
          />
        </div>
        <div className="secondary_menu_header_text">{secondaryHeader}</div>
        <div style={{ opacity: '0' }}>
          <img draggable="false"
            src="/images/arrow-left.png"
            alt="first_level menu"
            layout="fixed"
          />
        </div>
      </div>
      {secondaryHeader && parentLink && (
        <Link type="button" href={parentLink|| '#'} passHref>
          <div className="wrapper secondary_menu_parent_link ">
            <div className="secondary_menu_parent_link_title ">{`VIEW FULL ${secondaryHeader} RANGE`}</div>
            <img draggable="false"
              src="/images/arrow-right.png"
              alt="secondary_level menu"
              layout="fixed"
            />
          </div>
        </Link>
      )}
      {tabs && (
        <div className="wrapper">
          <div className="secondary-tab">
            {tabs.map((item, index) => {
              const itemList = items.filter(
                (n) => n.primary.column_handle === item.mega_nav_column_handle
              );
              const kspItems = ksps.filter(
                (n) => n.primary.column_handle === item.mega_nav_column_handle
              );
              const key = slugify(`${secondaryHeader}-${index}}`);
              return (
                <div className="second-level" key={key}>
                  <div className="menu_group">
                    <div
                      onFocus={() => undefined}
                      aria-hidden="false"
                      type="button"
                      onClick={() => {
                        setTertiaryItems(itemList);
                        setActiveTabIndex(index);
                        setKspsItems(kspItems);
                      }}
                      className={`second-level-title ${
                        activeTabIndex === index ? 'second-level-title-2' : null
                      }`}
                    >
                      {item.mega_nav_column_title}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {kspsItems && (
        <div className="wrapper ksps">
          {kspsItems[0]?.items?.map((item, index) => {
            const key = slugify(`ksp-${index}`);
            return (
              <div className="item_group" key={key}>
                {item?.ksp_icon?.url && (
                  <Image draggable={false}
                    src={item.ksp_icon.url}
                    width={22}
                    height={22}
                    alt={item.ksp_icon.alt || 'ksp icon'}
                  />
                )}
                {item.ksp_title}
              </div>
            );
          })}
        </div>
      )}
      <TertiaryMobile items={tertiaryItems} onClose={onClose} />
      {secondaryHeader && parentLink && (
        <Link type="button" href={parentLink|| '#'} passHref>
          <div className="wrapper secondary_menu_parent_link ">
            <div className="secondary_menu_parent_link_title ">{`VIEW FULL ${secondaryHeader} RANGE`}</div>
            <img draggable="false"
              src="/images/arrow-right.png"
              alt="secondary_level menu"
              layout="fixed"
            />
          </div>
        </Link>
      )}
      <style jsx>
        {`
          .secondary_menu_header_text {
            color: #949494;
            font-size: 13px;
            line-height: 20px;
            font-weight: 700;
            font-family: 'Roboto-Medium';
            text-transform: uppercase;
          }
          .secondary_menu_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            width: 100%;
            background: #141414;
            border-top: 1px solid rgba(228, 228, 228, 0.2);
            border-bottom: 1px solid rgba(228, 228, 228, 0.2);
          }
          .secondary-tab {
            display: flex;
            justify-content: center;
            padding: 24px 0;
            gap: 1px;
          }
          .secondary_menu_parent_link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 56px;
            width: 100%;
            background: #000000;
            border-bottom: 1px solid rgba(228, 228, 228, 0.2);
          }
          .secondary_menu_parent_link_title {
            text-transform: uppercase;
            font-size: 16px;
            line-height: 24px;
            color: #e4e4e4;
          }
          .second-level:first-child .second-level-title {
            border-radius: 4px 0 0 4px;
          }
          .second-level:last-child .second-level-title {
            border-radius: 0px 4px 4px 0px;
          }
          .second-level:only-child .second-level-title {
            border-radius: 4px;
          }
          .second-level-title {
            background: #242424;
            padding: 4px 8px;
            font-size: 13px;
            line-height: 16px;
            color: #e4e4e4;
            cursor: pointer;
            opacity: 75%;
            text-align: center;
          }
          .second-level-title-2 {
            color: #e4e4e4;
            background: #950810;
            opacity: 100%;
          }
          .ksps {
            font-size: 13px;
            line-height: 16px;
            color: #949494;
            display: flex;
            align-items: center;
            flex-flow: column wrap;
            gap: 20px;
          }
          .ksps .item_group {
            flex: 0 0 50%;
            display: flex;
            align-items: center;
            gap: 4px;
          }
          @media (min-width: ${breakPoints.small}) {
            .ksps .item_group {
              justify-content: center;
            }
          }
        `}
      </style>
    </div>
  );
};

export default memo(SecondaryMobile);
