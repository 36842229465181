import ButtonWithArrow from "components/ButtonWithArrow";
import Error from "components/Error";
import { useRouter } from "next/router";
import React from "react";

function ErrorPage() {
  const router = useRouter();
  return (
    <div>
      <Error code={404} />
      {/* <ButtonWithArrow
        arrowDirection="back"
        title="Return To Homepage"
        handleClick={() => router.push('/')}
      /> */}
    </div>
  );
}

export default ErrorPage;
